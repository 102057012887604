//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../helpers/firebase_helper";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import {
  postFakeLogin,
  postJwtLogin,
} from "../../helpers/fakebackend_helper";

import { apiError, apiSuccess, paymentStatus, userPackage, userCards } from './reducer';

import apiUrl from "slices/apiEndpoint";


export const createPayment = (values: any) => async (dispatch: any) => {
  try {
    const response = await axios.post(`${apiUrl}/payment/create-payment`, {
          userId:getDecodeId(),
          customerid:values.customerId,
          paymentMethodId:values.paymentMethodId,
          isVerified:true
    });
    let data = response.data;
    //console.log(data, "ddd")
    if (data) {
        dispatch(apiSuccess(data));
    }
  }
   catch (error) {
    dispatch(apiError(error));
  }
};

export const getPaymentStatus = () => async (dispatch: any) => {
  try {
    const response = await axios.get(`${apiUrl}/payment/get-payment-status/${getDecodeId()}`);
    let data = response.data;
    //console.log(data.isVerified, "ddd")
    if (data) {
        dispatch(paymentStatus(data.isVerified));
    }
  }
   catch (error) {
    dispatch(apiError(error));
  }
};

export const createSubscription = (userEmail: any, userPackage:any) => async (dispatch: any) => { 
  try { 
    const response = await axios.post(`${apiUrl}/stripe-sub/create-stripe-session-subscription`, { 
      email: userEmail,
      package:userPackage
    });
    let data = response.data;
    //console.log(data, "ddd")
    if (data) {
      dispatch(userPackage(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
}


export const getSubscribedCustomer = (userEmail: any) => async (dispatch: any) => { 
  try { 
    const response = await axios.post(`${apiUrl}/stripe-sub/get-subscribed-customer`, { 
      email: userEmail
    });
    let data = response.data;
    //console.log(data, "ksnkdk")
    if (data) {
      dispatch(userPackage(data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
}


export const checkPaymentStatus = () => async (dispatch: any) => { 
  try { 
    const response = await axios.get(`${apiUrl}/payment/check-user-payment-verified/${getDecodeId()}`);
    let data = response;
    if (data) {
      return data
    }
  } catch (error) {
    dispatch(apiError(error));
  }
}

export const getCards = (userId:any) => async (dispatch: any) => { 
  try { 
    const response = await axios.get(`${apiUrl}/stripe/api/get-saved-cards/${userId}`);
    let data = response;
    if (data) {
      dispatch(userCards(data.data))
      return data.data
    }
  } catch (error) {
    dispatch(apiError(error));
  }
}






function getDecodeId() {
   const token = localStorage.getItem('authUser')
    let decoded: any = "";
    if (token !== null) {
         decoded = jwtDecode(token);
      } else {
        console.error('Token is null');
    }
  return decoded.userId
}
 